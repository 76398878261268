import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Language } from '../../../interfaces';
import {
  ClusterAnswer,
  ClusterLanguage,
  ClusterQuestion,
} from '../../../shared/socket/socket.interfaces';

interface Props {
  readonly: boolean;
  currentLanguage: Language | undefined;
  questions: ClusterQuestion[];
  onScoreClick: (question_id: number, vote: number) => void;
}

const TaskContent = ({ readonly, currentLanguage, questions, onScoreClick }: Props) => {
  const { t } = useTranslation();

  const clickHandler = (question: ClusterQuestion, score: number): void =>
    onScoreClick(question.id, score);

  return (
    <div className="h-full w-full overflow-auto bg-white">
      <div>
        {/* Headings row */}
        <div className={clsx('hidden items-stretch justify-start px-4 py-6', 'lg:flex')}>
          <div className="flex w-1/2">
            <p className={clsx('text-xl font-semibold')}>{t('360.task.headline')}</p>
            <p className={clsx('lg:hidden')}>{t('360.task.headline.legend')}</p>
          </div>
          <div className="flex w-1/2">
            <div className="justify-top flex w-2/12 flex-col items-center text-center">
              <p className={clsx('c-label-muted-sm')}>{t('360.task.value.6.label')}</p>
            </div>
            <div className="justify-top flex w-2/12 flex-col items-center text-center">
              <p className={clsx('c-label-muted-sm')}>{t('360.task.value.5.label')}</p>
            </div>
            <div className="justify-top flex w-2/12 flex-col items-center text-center">
              <p className={clsx('c-label-muted-sm')}>{t('360.task.value.4.label')}</p>
            </div>
            <div className="justify-top flex w-2/12 flex-col items-center text-center">
              <p className={clsx('c-label-muted-sm')}>{t('360.task.value.3.label')}</p>
            </div>
            <div className="justify-top flex w-2/12 flex-col items-center text-center">
              <p className={clsx('c-label-muted-sm')}>{t('360.task.value.2.label')}</p>
            </div>
            <div className="justify-top flex w-2/12 flex-col items-center text-center">
              <p className={clsx('c-label-muted-sm')}>{t('360.task.value.1.label')}</p>
            </div>
          </div>
        </div>

        <div className={clsx('p-4', 'lg:hidden')}>
          <p className={clsx('font-semibold')}>{t('360.task.headline')}</p>
          <p>{t('360.task.headline.legend')}</p>
        </div>

        {/* Questions row */}
        {questions?.map((question: ClusterQuestion, index: number) => (
          <div
            key={`question-${question.id}`}
            className={clsx(
              'flex w-full flex-col items-stretch justify-start p-4',
              'lg:flex-row lg:px-4 lg:py-4',
              {
                'bg-white': index % 2 === 0,
                'bg-[#F9FAFB]': index % 2 !== 0,
              }
            )}
          >
            <div className={clsx('mb-3 flex w-full flex-col', 'lg:mb-0 lg:w-1/2')}>
              <p className={clsx('c-label-accent mb-2')}>
                {t(
                  `360.task.category.${question.languages.find((l: ClusterLanguage) => l.iso_code === currentLanguage?.iso_code)?.category}`
                )}
              </p>
              <p className={clsx('font-normal leading-6', 'lg:text-lg')}>
                {
                  question.languages.find(
                    (l: ClusterLanguage) => l.iso_code === currentLanguage?.iso_code
                  )?.text
                }
              </p>
            </div>
            <div
              className={clsx(
                'flex w-full flex-row-reverse items-start gap-3',
                'lg:w-1/2 lg:items-center'
              )}
            >
              {question.languages
                .find((l: ClusterLanguage) => l.iso_code === currentLanguage?.iso_code)
                ?.answers.map((v: ClusterAnswer, index: number) => (
                  <div
                    key={`question-${question.id}-score-${v.value}`}
                    className="flex w-2/12 flex-col items-center justify-center text-center"
                  >
                    <div
                      className={clsx(
                        'relative mb-3 h-[22px] w-[22px] cursor-pointer rounded-full border-2',
                        'lg:mb-0',
                        { 'border-[#767676] bg-white': question.score !== v.value },
                        { 'border-[#007A91] bg-[#007A91]': question.score === v.value },
                        { 'pointer-events-none cursor-default opacity-50': readonly }
                      )}
                      onClick={!readonly ? () => clickHandler(question, v.value) : () => null}
                    >
                      <input
                        className="invisible absolute left-0 top-0 z-20 h-full w-full"
                        name={`question-${question.id}-scores`}
                        readOnly
                        value={v.value}
                        checked={question.score === v.value}
                        type="radio"
                      />
                      {(question.score === v.value) !== null && (
                        <div className="absolute left-[50%] top-[50%] z-10 h-[8px] w-[8px] -translate-x-[50%] -translate-y-[50%] rounded-full bg-white" />
                      )}
                    </div>
                    <label
                      className={clsx('c-label-muted-sm', 'lg:hidden')}
                      htmlFor={`question-${question.id}-scores`}
                    >
                      {v.value === 0 ? v.text : t(`360.task.value.${index + 1}.label`)}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskContent;
