import clsx from 'clsx';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSocketInstance } from '../../App';
import SaveDraftDialog from '../../shared/components/Dialog/Contents/SaveDraftDialog';
import Dialog from '../../shared/components/Dialog/Dialog';
import PointsOfContent from '../../shared/components/PointsOfContent/PointsOfContent';
import PointsOfFooter from '../../shared/components/PointsOfFooter/PointsOfFooter';
import PointsOfHeader from '../../shared/components/PointsOfHeader/PointsOfHeader';
import { TaskFeedback } from '../../shared/socket/socket.interfaces';
import { STORE_FEEDBACK } from '../../store/exam/reducer';
import {
  getCompleteTask,
  getCurrentCluster,
  getPointOfStrengthFeedback,
  getSelectedTask,
  getTaskFeedbacks,
  getVotedId,
} from '../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { actions } from '../../store/ui/reducer';
import { getNextLoading, getPrevLoading } from '../../store/ui/selectors';

const PointsOfStrength = () => {
  // Hooks
  const { socket } = useSocketInstance();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // State
  const [text, setText] = useState<string>('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // Selectors
  const complete_task = useAppSelector(getCompleteTask);
  const current_cluster = useAppSelector(getCurrentCluster);
  const prevLoading = useAppSelector(getPrevLoading);
  const nextLoading = useAppSelector(getNextLoading);
  const voted_id = useAppSelector(getVotedId);
  const feedbacks = useAppSelector(getTaskFeedbacks);
  const feedback = useAppSelector(getPointOfStrengthFeedback);
  const selected_task = useAppSelector(getSelectedTask);
  // Computed
  const readonly = selected_task?.status === 'completed';

  // Handlers
  const changeHandler = (e: ChangeEvent<HTMLTextAreaElement>): void => setText(e.target.value);

  const goBackHandler = (_: MouseEvent<HTMLButtonElement>): void => {
    dispatch(STORE_FEEDBACK({ type: 'strength', text }));
    dispatch(actions.QUESTIONNAIRE_PREV_CLICKED());
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      socket.emit('feedback-prev', {
        voted_id,
        current_feedback: 'strength',
        feedbacks: feedbacks.map((f: TaskFeedback) => ({
          ...f,
          text: f.type === 'strength' ? text : f.text,
        })),
      });
      dispatch(actions.QUESTIONNAIRE_PREV_CLICKED());
    }, 500);
  };

  const goNextHandler = (_: MouseEvent<HTMLButtonElement>): void => {
    dispatch(STORE_FEEDBACK({ type: 'strength', text }));
    dispatch(actions.QUESTIONNAIRE_NEXT_CLICKED());
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      socket.emit('feedback-next', {
        voted_id,
        current_feedback: 'strength',
        feedbacks: feedbacks.map((f: TaskFeedback) => ({
          ...f,
          text: f.type === 'strength' ? text : f.text,
        })),
      });
      dispatch(actions.QUESTIONNAIRE_PREV_CLICKED());
    }, 500);
  };

  const saveDraftHandler = (): void => {
    socket.emit('save-draft', {
      voted_id,
      questions: [],
      feedbacks: feedbacks.map((f: TaskFeedback) => ({
        ...f,
        text: f.type === 'strength' ? text : f.text,
      })),
    });
  };

  // Effects
  useEffect(() => {
    dispatch(actions.QUESTIONNAIRE_DISABLE_PREV_LOADING());
    dispatch(actions.QUESTIONNAIRE_DISABLE_NEXT_LOADING());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (feedback && feedback.text) {
      setText(feedback.text);
    }
  }, [feedback]);

  return (
    <div className={clsx('flex h-full flex-col items-center justify-start', 'lg:justify-center')}>
      <div className={clsx('flex h-full w-full items-center justify-center', 'lg:max-w-[90%]')}>
        <div
          className={clsx(
            'flex h-full min-h-[400px] w-full flex-col items-center justify-start bg-[#FFFFFF]',
            'lg:max-h-[886px] lg:rounded-[8px] lg:shadow-cust-1'
          )}
        >
          {/* Header */}
          <PointsOfHeader fullname={current_cluster.name} />

          {/* Content */}
          <PointsOfContent
            title={t('360.pointsOfStrenght.textarea.title')}
            subtitle={t('360.pointsOfStrenght.textarea.subtitle')}
            readonly={readonly}
            text={text}
            onChange={changeHandler}
          />

          {/* Footer */}
          <PointsOfFooter
            currentQuestion={4}
            totalQuestion={complete_task.length + feedbacks.length}
            readonly={readonly}
            canGoBack={true}
            canGoNext={true}
            goBackLabel={t('360.button.back')}
            goNextLabel={t('360.button.next')}
            goBackLoading={prevLoading}
            goNextLoading={nextLoading}
            onSaveDraft={() => setIsDialogOpen(true)}
            onGoBack={goBackHandler}
            onGoNext={goNextHandler}
          />
        </div>
      </div>
      <Dialog isOpen={isDialogOpen}>
        <SaveDraftDialog
          onClose={() => setIsDialogOpen(false)}
          onConfirm={() => saveDraftHandler()}
        />
      </Dialog>
    </div>
  );
};

export default PointsOfStrength;
